<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
  >
  <v-card>
      <v-card-title>
        {{ itemState }} блокировку ТС
      </v-card-title>
      <v-divider />

      <v-card-text class="pt-4">
        <p>Укажите дату</p>
        <v-date-picker
          v-model="date"
          full-width
          :show-current="true"
          :first-day-of-week="1"
          :weekday-format="dayOfWeekToFormat"
        ></v-date-picker>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-row dense>
          <v-col>
            <v-btn
              outlined
              color="red"
              @click="dialog = false"
              :disabled="loading"
            >
               Отмена
            </v-btn>
          </v-col>

          <v-col class="text-right">
            <v-btn
              color="primary"
              @click="blockVehicle"
              :loading="loading"
              :disabled="!date"
            >
              Применить
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GeneralServices from '@/services/GeneralServices';
import {dayOfWeekToFormat} from "@/services/JetDate";

export default {
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },
  name: 'BlockVehicle',
  data: () => ({
    selected: null,
    date: new Date().toISOString().substr(0, 10),
    blockStatus: null,
    loading: false,
    dialog: true,
    isBlocking: null,
    toAction: null,
    fromAction: null,
    dayOfWeekToFormat: () => '',
  }),
  created() {
    this.dayOfWeekToFormat = dayOfWeekToFormat;
    this.selected = this.collection.master.selected;
    if (!this.selected) {
      this.show = false;
      jet.msg({
        text: 'Необходимо выбрать запись!',
        color: 'warning'
      });
      return;
    }

    this.blockStatus = this.selected.vcvehicleBlockstatus;
    this.isBlocking = this.blockStatus === 'Заблокировано' ? true : false;
    this.toAction = this.isBlocking ? 'разблокировки' : 'блокировки';
    this.fromAction = this.isBlocking ? 'блокировки' : 'разблокировки';

    const startDt = this.selected.vcvehicleStartdt;
    if (this.date < startDt) {
      jet.msg({
        text: `Дата ${fromAction} не может быть раньше даты начала учета ТС.`,
        color: 'warning',
      });
      return;
    }
  },
  computed: {
    itemState() {
      return this.isBlocking ? 'Cнять' : 'Установить';
    },
  },
  methods: {
    async blockVehicle(date, comment) {
      const id = jet.utils.uuidv4();
      const govNum = this.selected.vcvehicleGovnum;
      const checkBlockDt = await this.checkBlockDt(this.date, this.selected.vcvehicleId);
      let {id: userId, tenant: tenantId} = this.$store.state.profile;
      
      if(checkBlockDt){
        this.loading = true;
        const action = this.isBlocking ? 'Снять блокировку ТС' : 'Блокировать ТС';
        const opts = {
          type: 'core-create',
          query: 'sin2:/v:bda80f48-8257-4898-b7bb-157739e846a4',
          params: [
            { id: 'id', type: 'id', value: id },
            { id: 'vehicleId', type: 'id', value: this.selected.vcvehicleId },
            { id: 'blockDt', type: 'dateTime', value: this.date },
            { id: 'action', type: 'string', value: action},
            { id: 'actionDt', type: 'dateTime', value: this.date },
            { id: 'userId', type: 'id', value: userId /*this.selected._sec_attrsUserid*/ },
          ],
        };

        try {
          const resp = await jet.http.post(opts);
          if (resp.error) {
            throw resp.error;
          }
        } catch (e) {
          jet.msg({
            text: `Не удалось ${action} ${govNum}`,
            type: 'warning'
          });
          console.error('BlockVehicle: blockVehicle()', e);
        }
        this.loading = false;
        this.dialog = false;
        this.collection.refresh(this.selected.vcvehicleId);
        jet.msg({
          text: `Действие ${action} для ТС c регистрационным знаком ${govNum} выполнено успешно!`
        });
      } else {
        return;
      }
    },
    async checkBlockDt(date, vehicleId){
      const query = {
        type: 'core-read',
        query:`sin2:/v:010804f1-b69f-4df6-a559-1e3bb40694df/?filter=eq(field(".vehicleID"),param("${vehicleId}","id"))&fields=max(.blockDt)`,
      };
      try {
        const resp = await jet.http.post(query);
        const blockDt = resp.result.data[0][0];
        if (!!blockDt && date < blockDt) {
          this.dialog = false;
          jet.msg({
            text:`Дата ${this.toAction} не может быть раньше даты предыдущей ${this.fromAction}!`,
            color: 'warning',
          });
          return false;
        }
        if (resp.error) {
          throw resp.error;
        }
      } catch (e) {
        jet.msg({
          text: 'Не удалось получить максимальную дату блокировки/разблокировки ТС.',
          type: 'warning'
        });
        console.error('BlockVehicle: checkBlockDt()', e);
      }
      return true;
    }
  }
};
</script>
